import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import historyAccessor from 'history-accessor';
import { personalAccountPaths, userPaths } from 'routes/paths';
import PasswordValidation from 'utils/validate-password';
import { modalOpen, modalClose } from 'store/modals/modalsActions';
import { STAFF_TERMS_OF_SERVICE } from 'constants/modals';
import { notificationCreate } from 'app/notifications/notifications.actions';
import CommonService from 'api/common/common.service';
import { StaffAccountService } from 'api/staff/staff-account/staff-account.service';
import { getPersonalAccountUserPermissions } from 'store/userPreference/userPreference.reducer';
import { Actions as NavBarActions } from 'app/app-nav-bar/redux/app-nav-bar.actions';
import validator from 'services/validator';
import { getIsPersonalAccount } from 'store/auth/authReducer';
import { NationalitySelectItem } from 'api/core/area-short-info';
import config from 'config';
import {
    PersonalAccountReferralItem,
    PersonalAccountSignupRequest,
    PersonalAccountSignupResponse,
} from 'api/staff/staff-account/model';
import { AUTH_LOGIN } from 'store/actionTypes';

export enum PersonalAccountOnboardingStep {
    ClaimYourProfileStep = 1,
    AccountDetailsStep = 2,
}

class State {
    isLoading: boolean;
    isProcessing: boolean;
    currentStep: PersonalAccountOnboardingStep;
    firstName: string;
    lastName: string;
    jobTitle: string;
    squadId: number | null;
    club: string;
    nationality: NationalitySelectItem[];

    emailError: boolean;
    emailErrorText: string;
    email: string;
    password: string;
    retypePassword: string;
    passwordRules: Array<{ ruleCode: string; passed: boolean }>;
    passwordError: boolean;
    retypePasswordError: any;
    isAgreedTermsOfService: boolean;
    nationalitiesList: NationalitySelectItem[];

    verificationEmail: string;

    emailConfirmation: {
        isSuccess: boolean;
        isTokenExpired: boolean;
        isConfirmProcessing: boolean;
        onboardingGuid: string;
        referrals: PersonalAccountReferralItem[];
        selectedReferralsIds: number[];
    };
    token: string;
}

const defaultState: State = {
    isLoading: false,
    isProcessing: false,
    currentStep: PersonalAccountOnboardingStep.ClaimYourProfileStep,
    firstName: '',
    lastName: '',
    jobTitle: '',
    squadId: null,
    club: '',
    nationality: [],

    emailError: false,
    emailErrorText: '',
    email: '',
    password: '',
    retypePassword: '',
    passwordRules: [],
    passwordError: false,
    retypePasswordError: false,
    isAgreedTermsOfService: false,
    nationalitiesList: [],

    verificationEmail: '',

    emailConfirmation: {
        isSuccess: false,
        isTokenExpired: false,
        isConfirmProcessing: true,
        onboardingGuid: null,
        referrals: null,
        selectedReferralsIds: [],
    },
    token: null,
};

const stateController = new StateController<State>('PERSONAL_ACCOUNT_ONBOARDING', defaultState);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static init() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await dispatch(Actions.getOnboardingAccess());
                await dispatch(Actions.getNationalitiesList());
                dispatch(
                    stateController.setState({
                        currentStep: PersonalAccountOnboardingStep.ClaimYourProfileStep,
                    })
                );
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getOnboardingAccess() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const personalAccountUserPermissions =
                    getPersonalAccountUserPermissions(getState());

                if (!personalAccountUserPermissions?.personalAccount) {
                    historyAccessor.push(userPaths.home);
                } else {
                    const data = await StaffAccountService.getOnboardingAccess();

                    if (!data.isValid) {
                        historyAccessor.push(userPaths.home);
                    }

                    const nationalitiesExtended = data.nationalities.map((item) => ({
                        ...item,
                        ico: item.flagPath,
                        value: item.nationalityName,
                        label: item.nationalityName,
                    }));

                    dispatch(
                        stateController.setState({
                            firstName: data.firstName,
                            lastName: data.lastName,
                            jobTitle: data.jobTitle,
                            squadId: data.squad.id,
                            club: data.squad.name,
                            nationality: [...nationalitiesExtended],
                        })
                    );
                }
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static setCurrentStep(step: PersonalAccountOnboardingStep) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        };
    }

    public static goToAccountDetails() {
        return (dispatch) => {
            dispatch(Actions.setCurrentStep(PersonalAccountOnboardingStep.AccountDetailsStep));
        };
    }

    public static returnToClubProduct() {
        return (dispatch, getState: () => AppState) => {
            const isPersonalAccount = getIsPersonalAccount(getState());

            if (isPersonalAccount) {
                dispatch(NavBarActions.onSwitchAccount());
            } else {
                historyAccessor.push(userPaths.home);
            }
        };
    }

    public static onClaimProfileCancel() {
        return (dispatch) => {
            historyAccessor.push(userPaths.home);
        };
    }

    public static goToProfileSettings() {
        return (dispatch) => {
            historyAccessor.push(userPaths.editProfileProfile);
        };
    }

    public static onBackButtonClick() {
        return (dispatch) => {
            dispatch(Actions.setCurrentStep(PersonalAccountOnboardingStep.ClaimYourProfileStep));
        };
    }

    public static onConfirmAndClaimProfile() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const subsState = Selectors.getRoot(getState());

                const isEmailValid = validator.isValidEmail(subsState.email);
                if (!isEmailValid) {
                    return dispatch(
                        stateController.setState((prevState) => ({
                            ...prevState,
                            emailError: !isEmailValid,
                            emailErrorText: 'Incorrect email',
                        }))
                    );
                }

                const args: PersonalAccountSignupRequest = {
                    firstName: subsState.firstName,
                    lastName: subsState.lastName,
                    email: subsState.email,
                    password: subsState.password,
                    jobTitle: subsState.jobTitle,
                    nationalityAreaIds: subsState.nationality.map((item) => item.id),
                };

                const { token } = subsState;

                let data: PersonalAccountSignupResponse;
                if (token) {
                    data = await StaffAccountService.signupByToken(token, args);
                } else {
                    data = await StaffAccountService.signup(args);
                }

                if (data.isSuccess) {
                    if (token) {
                        await dispatch({
                            type: AUTH_LOGIN.SUCCESS,
                            payload: { data: data.auth },
                        });
                        historyAccessor.push(userPaths.personalEmailVerification);
                        dispatch(Actions.sendEmailConfirmation());
                    } else {
                        const redirectPath = `${personalAccountPaths.personalEmailVerification}?send-email`;
                        dispatch(NavBarActions.onSwitchAccount(redirectPath));
                    }
                } else if (data.isEmailExist) {
                    dispatch(
                        stateController.setState({
                            emailError: true,
                            emailErrorText: 'Email already exists',
                        })
                    );
                }
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static onFirstNameChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    firstName: value,
                })
            );
        };
    }

    public static onLastNameChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    lastName: value,
                })
            );
        };
    }

    public static onJobTitleChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    jobTitle: value,
                })
            );
        };
    }

    public static onNationalityChange(selected: NationalitySelectItem[]) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    nationality: selected,
                })
            );
        };
    }

    public static onEmailChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState({
                    email: value,
                    emailError: false,
                })
            );
        };
    }

    public static onEmailBlur() {
        return (dispatch, getState: () => AppState) => {
            // const { staffId } = Selectors.getRoot(getState());
            // dispatch(insertAnonymousActivity({
            //     PageName: 'Create Account',
            //     Message: 'Entered Email',
            //     CoachId: staffId
            // }));
        };
    }

    public static onPasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const rePassword = Selectors.getRoot(getState()).retypePassword;

            const validation = PasswordValidation.validate(value);
            const passwordError = !validation.isSuccess;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(
                value,
                rePassword
            );

            dispatch(
                stateController.setState({
                    password: value,
                    passwordError,
                    retypePasswordError,
                    passwordRules: validation.rules,
                })
            );
        };
    }

    public static onPasswordBlur() {
        return (dispatch, getState: () => AppState) => {
            // const { staffId } = Selectors.getRoot(getState());
            //
            // dispatch(insertAnonymousActivity({
            //     PageName: 'Create Account',
            //     Message: 'Entered Password',
            //     CoachId: staffId
            // }));
        };
    }

    public static onRetypePasswordChange(value: string) {
        return (dispatch, getState: () => AppState) => {
            const password = Selectors.getRoot(getState()).password;
            const retypePasswordError = PasswordValidation.validateConfirmPassword(password, value);

            dispatch(
                stateController.setState({
                    retypePassword: value,
                    retypePasswordError: retypePasswordError,
                })
            );
        };
    }

    public static onRetypePasswordBlur() {
        return (dispatch, getState: () => AppState) => {
            // const { staffId } = Selectors.getRoot(getState());
            //
            // dispatch(insertAnonymousActivity({
            //     PageName: 'Create Account',
            //     Message: 'Confirmed Password',
            //     CoachId: staffId
            // }));
        };
    }

    public static onTermsOfServiceCheckboxChange(isChecked: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    isAgreedTermsOfService: isChecked,
                }))
            );
        };
    }

    public static openTermsOfServiceModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(
                modalOpen({
                    id: STAFF_TERMS_OF_SERVICE,
                    content: {
                        closeModal: () => dispatch(Actions.closeTermsOfServiceModal),
                    },
                })
            );
        };
    }

    public static closeTermsOfServiceModal = () => (dispatch) => {
        dispatch(modalClose(STAFF_TERMS_OF_SERVICE));
    };

    public static sendEmailConfirmation() {
        return async (dispatch, getState: () => AppState) => {
            try {
                await StaffAccountService.sendConfirmEmail();
                dispatch(
                    notificationCreate({ message: 'Verification email sent!', level: 'success' })
                );
            } catch (err) {
                console.error(err);
            }
        };
    }

    public static resendVerificationEmail() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.sendEmailConfirmation());
        };
    }

    public static goToVerificationEmailPage() {
        return async (dispatch, getState: () => AppState) => {
            window.open(
                `${config.staffProductWebAppUrl}${personalAccountPaths.personalEmailVerification}`,
                '_self'
            );
        };
    }

    public static getNationalitiesList = () => {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await CommonService.getNationalitiesList();
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        nationalitiesList: data.map((item) => ({
                            ...item,
                            ico: item.flagPath,
                            value: item.nationalityName,
                            label: item.nationalityName,
                        })),
                    }))
                );
            } catch (e) {
                console.error(e);
            }
        };
    };

    public static getEmailConfirmationAccess = (sendEmail: boolean) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                const data = await StaffAccountService.getEmailConfirmationAccess();

                if (!data.isValid) {
                    historyAccessor.push('/');
                    return;
                }

                if (sendEmail) {
                    dispatch(Actions.sendEmailConfirmation());
                }

                dispatch(
                    stateController.setState({
                        verificationEmail: data.email,
                    })
                );
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    };

    public static validateGuid(guid: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        emailConfirmation: {
                            ...prevState.emailConfirmation,
                            isConfirmProcessing: true,
                        },
                    }))
                );
                const { isSuccess, isTokenExpired, referrals, onboardingGuid } =
                    await StaffAccountService.confirmEmailVerification(guid);

                if (!isSuccess && !isTokenExpired) {
                    // Needed to redirect to the home page if the token is invalid
                    historyAccessor.push('/');
                }

                const selectedReferralsIds = referrals?.map((item) => item.staffId);

                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        emailConfirmation: {
                            ...prevState.emailConfirmation,
                            selectedReferralsIds,
                            isSuccess,
                            isTokenExpired,
                            onboardingGuid,
                            referrals,
                        },
                    }))
                );
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        emailConfirmation: {
                            ...prevState.emailConfirmation,
                            isConfirmProcessing: false,
                        },
                    }))
                );
            }
        };
    }

    public static onClickConfirmationContinue() {
        return (dispatch, getState: () => AppState) => {
            const personalAccountUserPermissions = getPersonalAccountUserPermissions(getState());
            const isPersonalAccount = getIsPersonalAccount(getState());

            if (!!personalAccountUserPermissions?.personalAccount?.userId && !isPersonalAccount) {
                dispatch(NavBarActions.onSwitchAccount());
            } else {
                historyAccessor.push('/');
            }
        };
    }

    public static initByToken(token: string) {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await dispatch(Actions.getOnboardingAccessByToken(token));
                await dispatch(Actions.getNationalitiesList());
                dispatch(
                    stateController.setState({
                        currentStep: PersonalAccountOnboardingStep.ClaimYourProfileStep,
                        token: token,
                    })
                );
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static getOnboardingAccessByToken(token: string) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await StaffAccountService.getOnboardingAccessByToken(token);

                if (!data.isValid) {
                    historyAccessor.push(userPaths.home);
                }

                const nationalitiesExtended = data.nationalities.map((item) => ({
                    ...item,
                    ico: item.flagPath,
                    value: item.nationalityName,
                    label: item.nationalityName,
                }));

                dispatch(
                    stateController.setState({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        jobTitle: data.jobTitle,
                        squadId: data.squad?.id,
                        club: data.squad?.name,
                        nationality: [...nationalitiesExtended],
                    })
                );
            } catch (e) {
                console.error(e);
            }
        };
    }

    public static onReferralSelect(referralId: number) {
        return (dispatch, getState: () => AppState) => {
            const selectedReferralsIds = Selectors.getSelectedReferralsIds(getState());
            let updatedSelectedReferralsIds = [];
            const index = selectedReferralsIds.indexOf(referralId);

            if (index === -1) {
                updatedSelectedReferralsIds = [...selectedReferralsIds, referralId];
            } else {
                updatedSelectedReferralsIds = selectedReferralsIds.filter((x) => x !== referralId);
            }

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    emailConfirmation: {
                        ...prevState.emailConfirmation,
                        selectedReferralsIds: updatedSelectedReferralsIds,
                    },
                }))
            );
        };
    }

    public static onSaveReferralsAndContinue() {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState({ isProcessing: true }));
                const selectedReferralsIds = Selectors.getSelectedReferralsIds(getState());
                const { onboardingGuid } = Selectors.getEmailConfirmation(getState());

                await StaffAccountService.saveReferrals({
                    onboardingGuid,
                    referredStaffIds: selectedReferralsIds,
                });

                dispatch(Actions.onClickConfirmationContinue());
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ isProcessing: false }));
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.personalAccount.onboarding;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static isDataProcessing = (state: AppState) => Selectors.getRoot(state).isProcessing;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static getFirstName = (state: AppState) => Selectors.getRoot(state).firstName;
    public static getLastName = (state: AppState) => Selectors.getRoot(state).lastName;
    public static getJobTitle = (state: AppState) => Selectors.getRoot(state).jobTitle;
    public static getClub = (state: AppState) => Selectors.getRoot(state).club;
    public static getNationality = (state: AppState) => Selectors.getRoot(state).nationality;
    public static isFirstNameValid = (state: AppState) => !!Selectors.getFirstName(state);
    public static isLastNameValid = (state: AppState) => !!Selectors.getLastName(state);
    public static isJobTitleValid = (state: AppState) => !!Selectors.getJobTitle(state);
    public static isNationalityValid = (state: AppState) =>
        Selectors.getNationality(state).length > 0;
    public static getNationalitiesList = (state: AppState) =>
        Selectors.getRoot(state).nationalitiesList;

    public static getEmailError = (state: AppState) => Selectors.getRoot(state).emailError;
    public static getEmailErrorText = (state: AppState) => Selectors.getRoot(state).emailErrorText;
    public static getEmail = (state: AppState) => Selectors.getRoot(state).email;
    public static getPassword = (state: AppState) => Selectors.getRoot(state).password;
    public static getPasswordError = (state: AppState) => Selectors.getRoot(state).passwordError;
    public static getPasswordRules = (state: AppState) => Selectors.getRoot(state).passwordRules;
    public static getConfirmedPassword = (state: AppState) =>
        Selectors.getRoot(state).retypePassword;
    public static getConfirmedPasswordError = (state: AppState) =>
        Selectors.getRoot(state).retypePasswordError;
    public static isAgreedTermsOfService = (state: AppState) =>
        Selectors.getRoot(state).isAgreedTermsOfService;

    public static isClaimProfileDataInvalid = (state: AppState) => {
        return (
            !Selectors.isFirstNameValid(state) ||
            !Selectors.isLastNameValid(state) ||
            !Selectors.isJobTitleValid(state) ||
            !Selectors.isNationalityValid(state)
        );
    };

    public static getVerificationEmail = (state: AppState) =>
        Selectors.getRoot(state).verificationEmail;

    public static getEmailConfirmation = (state: AppState) =>
        Selectors.getRoot(state).emailConfirmation;
    public static getIsTokenExpired = (state: AppState) =>
        Selectors.getEmailConfirmation(state).isTokenExpired;
    public static getIsProcessing = (state: AppState) =>
        Selectors.getEmailConfirmation(state).isConfirmProcessing;
    public static getIsSuccess = (state: AppState) =>
        Selectors.getEmailConfirmation(state).isSuccess;
    public static getReferrals = (state: AppState) =>
        Selectors.getEmailConfirmation(state).referrals;
    public static getSelectedReferralsIds = (state: AppState) =>
        Selectors.getEmailConfirmation(state).selectedReferralsIds;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller,
};
