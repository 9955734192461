import { ProductsEnum } from 'models/products.model';
import config from 'config';

export const getCurrentProduct = (): ProductsEnum => {
    return window.location.origin === config.staffProductWebAppUrl
        ? ProductsEnum.Staffroom
        : ProductsEnum.Transferroom;
};

export const isStaffRoomProduct = (): boolean => {
    return getCurrentProduct() === ProductsEnum.Staffroom;
};

export const isTransferRoomProduct = (): boolean => {
    return getCurrentProduct() === ProductsEnum.Transferroom;
};
