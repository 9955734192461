import axios from "axios-config";
import {
    ClubPermissionsFilterOptions,
    ClubPermissionsFilterOptionsExtended,
    Country,
    SquadModel,
    SubscriptionPlans,
    SubscriptionSquadModel,
    SupervisorsFetch,
    EditCostValueOption,
    ClubPermissionsModel
} from './models'

export class ClubPermissionsService {
    private static axiosConfig = {
        headers: { "Content-Type": "application/json" },
    };

    public static async getSquadPermissionsByFilterOptions(filterOptions: ClubPermissionsFilterOptions): Promise<Array<SquadModel>> {
        const { data } = await axios.post(
            `api/v2/AdminSquads/GetSquadsByFilter`,
            { ...filterOptions },
            this.axiosConfig
        )
        return data
    }

    public static async getSquadPermissionsByFilterOptionsWithPaging(filterOptions: ClubPermissionsFilterOptionsExtended): Promise<ClubPermissionsModel> {
        const { data } = await axios.post(
            `api/v2/AdminSquads/GetSquadsByFilterPaging`,
            { ...filterOptions },
            this.axiosConfig
        )
        return data
    }

    public static async getCountries(userId): Promise<Array<Country>> {
        const { data } = await axios.get(
            `api/Competition/GetAreasWithCompetitions/${userId}?withSubSquads=true&onlyWithUsers=false`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSubscriptionPlans(): Promise<SubscriptionPlans> {
        const { data } = await axios.get(
            `api/AdminClubPermissions/GetSubscriptionPlans`,
            this.axiosConfig
        );
        return data;
    }

    public static async supervisorsFetch(): Promise<SupervisorsFetch> {
        const { data } = await axios.get(
            `api/AdminSquads/GetSalesSupervisorUsers`,
            this.axiosConfig
        );
        return data;
    }

    public static async competitionsFetch(): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/Competition/GetAll`,
            this.axiosConfig
        );
        return data;
    }

    public static async getPermissionTypes(): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/AdminClubPermissions/GetPermissionTypes`,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSquadsWithSubscriptions(competitionId): Promise<Array<SubscriptionSquadModel>> {
        const { data } = await axios.get(
            `api/AdminClubPermissions/GetClubSubscriptions/${competitionId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async laodSquadsBySubscriptionPlanId(subscriptionPlanId): Promise<Array<any>> {
        let url = `api/AdminClubPermissions/GetSquadsBySubscriptionPlanId`;
        if (subscriptionPlanId !== null) {
            url = `api/AdminClubPermissions/GetSquadsBySubscriptionPlanId?subscriptionPlanId=${subscriptionPlanId}`;
        }
        const { data } = await axios.get(
            url,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSquadsByPromotionPlanId(planId): Promise<Array<any>> {
        let url = `api/AdminClubPermissions/GetSquadsByPromotionPlanId`;
        if (planId !== null) {
            url = `api/AdminClubPermissions/GetSquadsByPromotionPlanId?planId=${planId}`;
        }
        const { data } = await axios.get(
            url,
            this.axiosConfig
        );
        return data;
    }

    public static async loadSquadsBySupervisorId(supervisorId): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/AdminClubPermissions/GetClubSubscriptionsBySupervisor/${supervisorId}`,
            this.axiosConfig
        );
        // dispatch(getSquadsBySupervisorId(supervisorId));
        return data;
    }

    public static async getSquads(competitionId): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/Squad/GetSquadsByCompetitionIdAdmin/${competitionId}/42706`,
            this.axiosConfig
        );
        return data;
    }

    public static async getSquadsBySupervisorId(supervisorId): Promise<Array<any>> {
        const { data } = await axios.get(
            `api/AdminClubPermissions/GetSquadsBySupervisorId/${supervisorId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async allowLeague(squadId, permissionCode, leagueId, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/AllowLeague`,
            { squadId: squadId, permissionCode: permissionCode, leagueId: leagueId },
            this.axiosConfig
        );
        return data;
    }

    public static async disallowLeague(squadId, permissionCode, leagueId, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/DisallowLeague`,
            { squadId: squadId, permissionCode: permissionCode, leagueId: leagueId },
            this.axiosConfig
        );
        return data;
    }

    public static async allowAllLeagues(squadId, permissionCode, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/AllowAllLeagues`,
            { squadId: squadId, permissionCode: permissionCode },
            this.axiosConfig
        );
        return data;
    }

    public static async disallowAllLeagues(squadId, permissionCode, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/DisallowAllLeagues`,
            { squadId: squadId, permissionCode: permissionCode },
            this.axiosConfig
        );
        return data;
    }

    public static async setSquadSubscription(squadId, subscriptionPlanId, currentSquadId) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/SetSquadSubscription/${currentSquadId}`,
            { squadId: squadId, subscriptionPlanId: subscriptionPlanId },
            this.axiosConfig
        );
        return data;
    }

    public static async setSquadPromotionPlan(squadId, promotionPlanId, expiration, currentSquadId) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/SetSquadPromotionPlan/${currentSquadId}`,
            { squadId: squadId, promotionPlanId: promotionPlanId, expiration: expiration },
            this.axiosConfig
        );
        return data;
    }

    public static async allowAction(squadId, permissionCode, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/AllowAction`,
            { squadId: squadId, permissionCode: permissionCode },
            this.axiosConfig
        );
        return data;
    }

    public static async disallowAction(squadId, permissionCode, old = false) {
        const { data } = await axios.post(
            `api/AdminClubPermissions/DisallowAction`,
            { squadId: squadId, permissionCode: permissionCode },
            this.axiosConfig
        );
        return data;
    }

    public static async editPermissionCostValue(squadId: number, permissionCode: string, value: string | number): Promise<any> {
        const { data } = await axios.post(
            `api/AdminClubPermissions/EditCostValue`,
            { squadId: squadId, permissionCode: permissionCode, value: value },
            this.axiosConfig
        );
        return data;
    }

    public static async assignSupervisor(squadId: number, supervisorUserId: number): Promise<any> {
        const { data } = await axios.post(
            `api/AdminSquads/AssignSupervisorToSquad/${squadId}/${supervisorUserId}`,
            { id: squadId, supervisorId: supervisorUserId },
            this.axiosConfig
        );
        return data;
    }

    public static async editStaffProductSubscription(squadId: number, value: string | number): Promise<any> {
        const { data } = await axios.post(
            `api/AdminClubPermissions/EditStaffProductSubscription`,
            { squadId: squadId, value: value },
            this.axiosConfig
        );
        return data;
    }
}