import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor'
import { agencyPaths } from 'routes/paths';
import { PlayerVerificationExtended, PlayerVerificationItemStatusEnum } from 'api/agency/agent/landing/agency-dashboard';
import AgencyHomePageService from 'api/agency/agent/landing/agency-homepage.service';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import AgencyPlayerSwapService from 'api/agency/agent/landing/agency-player-swap.service';

export enum SwapPlayerStepsEnum {
    SwapPlayerInfo = 1,
    SelectPlayer = 2,
    SwapPlayerConfirm = 3,
    SwapPlayerSuccess = 4,
    SwapPlayerUnable = 5,
    DowngradeSuccess = 6
}

class State {
    isLoading: boolean;
    shareToggle: boolean;
    processing: boolean;
    priorityPlayerCreditSwaps: number;
    priorityCreditsRemaining: number;
    isAnnualCreditSwapWindowActive: boolean;
    hasShareInsightPermission: boolean;
    currentStep: SwapPlayerStepsEnum;
    playerOut: PlayerVerificationExtended | null;
    playerIn: PlayerVerificationExtended | null;
    playersForSwap: PlayerVerificationExtended[];
    players: PlayerVerificationExtended[];
    playerInvitationSent: PlayerVerificationExtended[];
}

const defaultState: State = {
    isLoading: false,
    shareToggle: true,
    processing: false,
    priorityPlayerCreditSwaps: 0,
    priorityCreditsRemaining: 0,
    isAnnualCreditSwapWindowActive: false,
    hasShareInsightPermission: false,
    currentStep: SwapPlayerStepsEnum.SwapPlayerInfo,
    playerOut: null,
    playerIn: null,
    playersForSwap: [],
    players: [],
    playerInvitationSent: null,
}

const stateController = new StateController<State>(
    'AGENCY/SWAP-PLAYER',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        }
    }

    public static init(id: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const substate = getState().agency.landingPage.homePagePlayers;
                const isDowngraded = substate.downgradePlayerModal.isDowngraded;
                // To add a test mode for credit swap, use dateTest
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;

                if (!id && isDowngraded) {
                    dispatch(Actions.setStep(SwapPlayerStepsEnum.DowngradeSuccess));
                    return;
                }
                dispatch(stateController.setState({ isLoading: true }));
                const data = await AgencyHomePageService.getPlayers();
                const allPlayers = data.players.filter(x => x.verifiedStatus == PlayerVerificationItemStatusEnum.VerifiedByAdmin)
                const playerOut = allPlayers.find(player => player.playerId === id);
                const playersForSwap = allPlayers.filter(x => x.isRegular && x.playerId !== id);
                const { priorityCreditsRemaining, isAnnualCreditSwapWindowActive, hasShareInsightPermission } = data;

                // if ((priorityCreditsRemaining > 0 && !isAnnualCreditSwapWindowActive) || !playerOut) {
                //     dispatch(Actions.setStep(SwapPlayerStepsEnum.SwapPlayerUnable))
                // }
                if (!isAnnualCreditSwapWindowActive || !playerOut) {
                    dispatch(Actions.setStep(SwapPlayerStepsEnum.SwapPlayerUnable))
                }

                if (playerOut) {
                    dispatch(stateController.setState({
                        playerOut,
                        playersForSwap,
                        priorityCreditsRemaining,
                        isAnnualCreditSwapWindowActive,
                        hasShareInsightPermission,
                        shareToggle: hasShareInsightPermission ? true : false,
                        priorityPlayerCreditSwaps: data.priorityPlayerCreditSwaps,
                        players: data.players,
                        playerInvitationSent: data.playerInvitationsSent
                    }))
                }
            } catch (err) {
                console.error(err)
            } finally {
                dispatch(stateController.setState({ isLoading: false }))
            }
        }
    }

    public static setStep(step: SwapPlayerStepsEnum) {
        return (dispatch) => {
            dispatch(stateController.setState({ currentStep: step }));
        }
    }

    public static onContinue() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playerOut = Selectors.getPlayerOutData(appState);
            dispatch(stateController.setState({ currentStep: SwapPlayerStepsEnum.SelectPlayer }));

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Credits Swap for Player'),
                Message: `Clicked Continue: ${playerOut.playerName}`,
                PlayerId: playerOut.playerId
            }));
        }
    }

    public static onConfirmSelect() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playerIn = Selectors.getPlayerInData(appState);
            dispatch(stateController.setState({ currentStep: SwapPlayerStepsEnum.SwapPlayerConfirm }));

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Credits Swap Select Player'),
                Message: `Clicked Confirm: ${playerIn.playerName}`,
                PlayerId: playerIn.playerId
            }));
        }
    }

    public static onCancelSwap(playerId?: number) {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const currentStep = Selectors.getCurrentStep(getState());
            const playersForSwap = Selectors.getPlayersForSwap(getState());

            if (currentStep === SwapPlayerStepsEnum.SwapPlayerInfo) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Credits Swap for Player'),
                    Message: `Clicked Cancel`,
                    PlayerId: playerId
                }));
            }

            if (currentStep === SwapPlayerStepsEnum.SelectPlayer && playersForSwap.length === 0) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Credits Swap No Player'),
                    Message: `Clicked Cancel`,
                }));
            }

            if (currentStep === SwapPlayerStepsEnum.SelectPlayer && playersForSwap.length > 0) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Credits Swap Select Player'),
                    Message: `Clicked Cancel`,
                }));
            }

            if (currentStep === SwapPlayerStepsEnum.SwapPlayerConfirm) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Confirm Player Swap'),
                    Message: `Clicked Cancel`,
                }));
            }

            if (currentStep === SwapPlayerStepsEnum.SwapPlayerUnable) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Credits Swap for Player Unavailable'),
                    Message: `Clicked Cancel`,
                }));
            }

            historyAccessor.push(`${agencyPaths.landingPagePlayers}`);
        }
    }

    public static onDoneSwap() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playerIn = Selectors.getPlayerInData(appState);

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Player Swap Success'),
                Message: `Clicked Done`,
                PlayerId: playerIn.playerId,
            }));

            historyAccessor.push(`${agencyPaths.landingPagePlayers}`);
        }
    }

    public static onDoneDowngrade() {
        return (dispatch, getState: () => AppState) => {
            const substate = getState().agency.landingPage.homePagePlayers;
            const playerId = substate.downgradePlayerModal.playerId;

            dispatch(userActivityInsert({
                PageName: `Agency Players [Downgrade Player]`,
                Message: `Clicked Done`,
                PlayerId: playerId,
            }));

            historyAccessor.push(`${agencyPaths.landingPagePlayers}`);
        }
    }

    public static moveToAddPlayers() {
        return () => {
            historyAccessor.push(`${agencyPaths.addPlayers}`);
        }
    }

    public static selectPlayerIn(playerIn: PlayerVerificationExtended) {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            dispatch(stateController.setState({ playerIn }));

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Credits Swap Select Player'),
                Message: `Selected Verified Player: ${playerIn.playerName}`,
                PlayerId: playerIn.playerId
            }));
        }
    }

    public static toggleShare() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const substate = Selectors.getRoot(appState);
            const playerIn = Selectors.getPlayerInData(appState);

            const newShareToggle = !substate.shareToggle;
            dispatch(stateController.setState({
                ...substate,
                shareToggle: newShareToggle
            }));

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Confirm Player Swap'),
                Message: `${newShareToggle ? 'Checked' : 'Unchecked'} Share Insights: ${playerIn.playerName}`,
                PlayerId: playerIn.playerId,
            }));
        }
    }

    public static confirmPlayerSwap() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const appState = getState();
                dispatch(stateController.setState({ processing: true }));
                const shareToggle = Selectors.getShareToggle(appState);
                const playerOut = Selectors.getPlayerOutData(appState);
                const playerIn = Selectors.getPlayerInData(appState);
                // To add a test mode for credit swap use dateTest
                // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;

                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Confirm Player Swap'),
                    Message: `Clicked Confirm Player Swap: ${playerIn.playerName}`,
                    PlayerId: playerIn.playerId,
                }));

                await AgencyPlayerSwapService.swapOutPlayers(playerIn.playerId, playerOut.playerId, shareToggle);

                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Player Swap Success'),
                    Message: `Player Swap Successful: ${playerIn.playerName}`,
                    PlayerId: playerIn.playerId,
                }));

                dispatch(Actions.setStep(SwapPlayerStepsEnum.SwapPlayerSuccess));
            } catch (err) {
                console.error(err);
            } finally {
                dispatch(stateController.setState({ processing: false }));
            }
        }
    }

    public static initSwapPlayerInfoStep() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const priorityPlayerCreditSwaps = Selectors.getPriorityPlayerCreditSwaps(getState());
            const playerOut = Selectors.getPlayerOutData(getState());

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Credits Swap for Player'),
                Message: `Viewed Swap Credits for Player: ${playerOut.playerName}`,
                PlayerId: playerOut.playerId
            }));
        }
    }

    public static initSelectPlayerStep() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playersForSwap = Selectors.getPlayersForSwap(getState());
            if (playersForSwap.length === 0) {
                dispatch(userActivityInsert({
                    PageName: Selectors.getPageName(appState, 'Credits Swap No Player'),
                    Message: 'Verified Players Unavailable',
                }));
            }
        }
    }

    public static initSwapPlayerConfirmStep() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playerIn = Selectors.getPlayerInData(appState);

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Confirm Player Swap'),
                Message: `Viewed Player Swap Confirmation: ${playerIn.playerName}`,
                PlayerId: playerIn.playerId
            }));
        }
    }

    public static initSwapPlayerUnableStep() {
        return (dispatch, getState: () => AppState) => {
            const appState = getState();
            const playerOut = Selectors.getPlayerOutData(appState);

            dispatch(userActivityInsert({
                PageName: Selectors.getPageName(appState, 'Credits Swap for Player Unavailable'),
                Message: 'Viewed Swap Window Closed',
                PlayerId: playerOut.playerId
            }));
        }
    }
}

class Selectors {
    public static getRoot = (state: AppState): State => state.agency.swapPlayer;
    public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
    public static getPriorityPlayerCreditSwaps = (state: AppState) => Selectors.getRoot(state).priorityPlayerCreditSwaps;
    public static getPriorityCreditsRemaining = (state: AppState) => Selectors.getRoot(state).priorityCreditsRemaining;
    public static getPlayerOutData = (state: AppState) => Selectors.getRoot(state).playerOut;
    public static getPlayerInData = (state: AppState) => Selectors.getRoot(state).playerIn;
    public static getPlayersForSwap = (state: AppState) => Selectors.getRoot(state).playersForSwap;
    public static getShareToggle = (state: AppState) => Selectors.getRoot(state).shareToggle;
    public static getPlayers = (state: AppState) => Selectors.getRoot(state).players;
    public static getPlayerInvitationSent = (state: AppState) => Selectors.getRoot(state).playerInvitationSent;
    public static getProcessing = (state: AppState) => Selectors.getRoot(state).processing;
    public static getPageName = (state: AppState, type: string) => {
        const { isAnnualCreditSwapWindowActive } = Selectors.getRoot(state);
        return `Agency Players [${isAnnualCreditSwapWindowActive ? "Swap Window " : ""}${type}]`;
    };
    public static getHasShareInsightPermission = (state: AppState) => Selectors.getRoot(state).hasShareInsightPermission;
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    State as State,
    Actions as Actions,
    stateController as Controller,
    Selectors as Selectors
};