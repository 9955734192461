import { createAction } from 'redux-actions';
import {
    ROUTE_CHANGED,
    AUTH_CHECK_EMAIL,
    AUTH_LOGIN,
    AUTH_REFRESH,
    AUTH_LOGOUT,
    AUTH_CHANGE_STEP,
    AUTH_RESTORE,
    AUTH_LOGIN_SCREEN_DISPOSE,
    AUTH_LOGOUT_GLOBAL_DISPOSE,
    AUTH_DEACTIVATE_AGENCY_FEATURE,
    AUTH_SET_UP_AGENCY_FEATURE,
    REFRESH_SET_AGENCY,
    AUTH_RESET_LOGIN_ERROR,
    AUTH_SET_ERRORS,
    AUTH_ISBLOCKED_CHANGE_STEP,
    AUTH_GENERATE_CHECK_EMAIL_TOKEN,
    AUTH_META,
    AUTH_HIDE_TERMS_AND_CONDITIONS, AUTH_ISBLOCKED_LOADING, UNBLOCK_ACCOUNT, AUTH_LOGIN_SCREEN_CLEANUP
} from '../actionTypes';
import createApiThunk, { createApi, createApiThunkWithBearer } from '../../services/createApiThunk';
import throttle from 'lodash/throttle';
import userActivityInsert, {insertAnonymousActivity} from '../../app/user-activity/actions/user-activity.actions';
import { saveState, clearState } from 'store/localStorageState';
import { UserTypeEnum } from 'constants/enums';
import { AuthInfoModel, AuthStep, ServerErrors } from './auth-state'
import { AppState } from 'root.reducer';
import { RefObject } from 'react';
import * as storedFilter from 'pages/PlayerSearch-v2/redux/stored-filter'
import { Actions as AdminSettingsActions } from 'admin-v2/modals/settings-modal/settings-modal.controller'
import useAppcues, {resetAppcues} from '../../hooks/use-add-appcues'
import config from 'config';
import { staffProductPaths } from 'routes/paths';
import { getCurrentProduct } from 'utils/products';
import { ProductsEnum } from 'models/products.model';

export const routeChanged = createAction(ROUTE_CHANGED);
export const restoreAuth = createAction(AUTH_RESTORE);
export const disposeLoginUiState = createAction(AUTH_LOGIN_SCREEN_DISPOSE);
export const resetLoginError = createAction(AUTH_RESET_LOGIN_ERROR);

export function goToStep(step: AuthStep) {
    return (dispatch, getState: () => AppState) => {
        dispatch({ type: AUTH_CHANGE_STEP, payload: step })
    }
}
export function dispose() {
    return (dispatch, getState: () => AppState) => {
        dispatch({ type: AUTH_LOGIN_SCREEN_CLEANUP})
    }
}
export function unblockAccount(email: string) {
    return async (dispatch, getState: () => AppState) => {
        dispatch({ type: AUTH_ISBLOCKED_LOADING, payload: true });
        const request = {
            type: UNBLOCK_ACCOUNT,
            method: 'post',
            url: 'Users/UnblockAccount',
            payload: {
                email: email
            },
        };
        const data = await dispatch(createApiThunk(request));
        dispatch(
            insertAnonymousActivity({
                Message: 'Clicked Unblock Your Account',
                PageName: 'Log in [Account Blocked]',
            }),
        );
        if(data.success) {
            dispatch({type: AUTH_ISBLOCKED_CHANGE_STEP, payload: AuthStep.ThankYou});
            dispatch({ type: AUTH_ISBLOCKED_LOADING, payload: false });
        }
    }
}

export function goBackAndChangeEmail(emailInputRef: RefObject<HTMLElement>) {
    return (dispatch, getState: () => AppState) => {
        dispatch(goToStep(AuthStep.Email))
        emailInputRef.current.focus()
    }
}

export function generateToken() {
    return async (dispatch, getState: () => AppState) => {
        const request = {
            type: AUTH_GENERATE_CHECK_EMAIL_TOKEN,
            method: 'post',
            url: `Users/GenerateCheckEmailToken`,
        };

        await dispatch(createApiThunk(request, null))
    }
}
export function isFromLogout() {
    return (dispatch, getState: () => AppState) => {
        const isFromLogout = JSON.parse(localStorage.getItem('IS_LOGOUT')) ?? {};
        return isFromLogout ? isFromLogout.location : null
    }
}

export function checkEmail(email, token, callback) {
    return async (dispatch, getState: () => AppState) => {
        const request = {
            type: AUTH_CHECK_EMAIL,
            method: 'post',
            url: `Users/CheckUserEmail/${token}`,
            payload: {
                email: email
            },
        };

        const response = await dispatch(createApiThunkWithBearer(request, token));
        const isEmailExists = response.value;

        if (isEmailExists) {
            setError({ emailError: false })
            dispatch(goToStep(AuthStep.Password))
            callback()
        } else {
            dispatch(setError({ emailError: true }))
        }
    }
}

export function setError(payload: ServerErrors) {
    return (dispatch, getState: () => AppState) => {
        const errors = getState().auth.serverError
        dispatch({
            type: AUTH_SET_ERRORS, payload: {
                ...errors,
                ...payload
            }
        })
    }
}

const LOCAL_STORAGE_KEY = 'playerReferralBanner'

const commonLogIn = async (dispatch, getState: () => AppState, request, isSignInProcess: boolean) => {
    const currentUserAuth = getState().auth;
    if (currentUserAuth.userId === null && !isSignInProcess) return;

    const auth: AuthInfoModel = await dispatch(createApiThunk(request));

    if (auth !== undefined && auth.isSuccessful) {
        let authState = getState().auth;
        const { userId } = authState;

        if (authState.userTypeId !== UserTypeEnum.FriendlyMatchSupervisor) {
            // #5195 - Friendly user - a friendly user sees "Force Updater" if he was switched from user who has an old ad
        }

        const currentProduct = getCurrentProduct();
        if (currentProduct === ProductsEnum.Staffroom) {
            if(authState.userTypeId !== UserTypeEnum.PersonalClubAccount && !authState.clubPermission?.isStaffProductEnabled) {
                window.open(`${config.webAppUrl}/`, '_self');
            }
        } else {
            if(authState.userTypeId === UserTypeEnum.PersonalClubAccount) {
                window.open(`${config.staffProductWebAppUrl}${staffProductPaths.homePage}`, '_self');
            }
        }

        saveState({ auth: authState });
        useAppcues(dispatch, authState);

        if (authState.userTypeId === UserTypeEnum.Player) {
            const localStorageInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) ?? {}

            if (!localStorageInfo[userId]) {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                    ...localStorageInfo,
                    [userId]: {
                        ...localStorageInfo[userId],
                        logInCounter: 1,
                        dismissedDate: null
                    }
                }))
            } else {
                localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
                    ...localStorageInfo,
                    [userId]: {
                        ...localStorageInfo[userId],
                        logInCounter: localStorageInfo[userId].logInCounter + 1
                    }
                }))
            }
        }
        
        dispatch(
            userActivityInsert({
                Message: 'Logged In',
                PageName: 'Landing Page',
            }),
        );

        const adminUserTypes = [
            UserTypeEnum.GlobalAdmin,
            UserTypeEnum.Admin,
            UserTypeEnum.AdminEditor
        ];

        if (adminUserTypes.includes(auth.userTypeId)) {
            dispatch(AdminSettingsActions.initSettings())
        }
    }
}

export async function logIn({ userLogin, password }, returnUrl = null) {
    let uri = 'Users/Login';

    let isSignInProcess = false;

    if (userLogin && password) {
        isSignInProcess = true;
    }

    return async (dispatch, getState) => {
        const request = {
            type: AUTH_LOGIN,
            method: 'post',
            url: uri,
            payload: {
                userLogin: userLogin,
                password: password,
            },
        };

        commonLogIn(dispatch, getState, request, isSignInProcess);
    };
}

export function logInV2({ userLogin, password }, returnUrl = null) {
    let uri = 'Users/LoginV2';

    let isSignInProcess = false;

    if (userLogin && password) {
        isSignInProcess = true;
    }

    return async (dispatch, getState) => {
        const request = {
            type: AUTH_LOGIN,
            method: 'post',
            url: uri,
            payload: {
                userLogin: userLogin,
                password: password,
            },
        };

        await commonLogIn(dispatch, getState, request, isSignInProcess);
    };
}

const refreshAuthDebounced = throttle(
    async (dispatch, aspNetUserId, getState) => {
        let currentUserId = getState().auth.userId;
        if (currentUserId !== null) {
            let uri = 'Users/RefreshAuth';

            if (aspNetUserId) {
                uri += `?id=${encodeURIComponent(aspNetUserId)}`;
            }

            const request = {
                type: AUTH_REFRESH,
                method: 'post',
                url: uri,
            };

            await dispatch(createApiThunk(request));
            let authState = getState().auth;
            saveState({ auth: authState });
            useAppcues(dispatch, authState);
        }
    },
    60 * 1000,
);

export function refreshAuth(aspNetUserId) {
    return (dispatch, getState) => {
        refreshAuthDebounced(dispatch, aspNetUserId, getState);
    };
}

export function getMeta() {
    return async (dispatch, getState) => {
        let currentUserId = getState().auth.userId;
        if (currentUserId == 0 || currentUserId == null) {
            resetAppcues(dispatch);
            await dispatch(createApiThunk({
                type: AUTH_META,
                method: 'get',
                url: 'Users/GetMeta',
            }));
        }
        else{
            let authState = getState().auth;
            useAppcues(dispatch, authState);
        }
    }
}

export function refreshAuthSingle(aspNetUserId) {
    return async (dispatch, getState) => {
        let currentUserId = getState().auth.userId;
        if (currentUserId !== null) {
            let uri = 'Users/RefreshAuth';

            if (aspNetUserId) {
                uri += `?id=${encodeURIComponent(aspNetUserId)}`;
            }

            const request = {
                type: AUTH_REFRESH,
                method: 'post',
                url: uri,
            };

            let res = await dispatch(createApiThunk(request));
            let authState = getState().auth;
            saveState({ auth: authState });

            if ((res || {}).active != true) {
                await dispatch(logout());
            }
        }
    };
}

export function refreshAuthAgencyFeatureEnabled() {
    return (dispatch, getState) => {
        dispatch(createAction(REFRESH_SET_AGENCY.SUCCESS)());
    }
}

export function logout(callBack?: () => void) {
    return async (dispatch, getState) => {
        const request = {
            type: AUTH_LOGOUT,
            method: 'post',
            url: '/Users/Logout',
        };
        await dispatch(createApiThunk(request));
        window.location.replace('/');
        clearState();
        storedFilter.clearFilter()
        callBack && callBack()
    };
}

export function logoutWithRedirect(url) {
    return async (dispatch, getState) => {
        const request = {
            type: AUTH_LOGOUT,
            method: 'post',
            url: '/Users/Logout',
        };
        await dispatch(createApiThunk(request));
        window.location.replace(url);
        clearState();
        storedFilter.clearFilter()
    };
}

export function logoutWithoutRedirect() {
    return async (dispatch, getState) => {
        const request = {
            type: AUTH_LOGOUT,
            method: 'post',
            url: '/Users/Logout',
        };
        await dispatch(createApiThunk(request));
        dispatch(createAction(AUTH_LOGOUT_GLOBAL_DISPOSE)());
        clearState();
        storedFilter.clearFilter()
    }
}
export function getUserPageName() {
    return (dispatch, getState) => {
        const userTypeId = getState().auth.userTypeId;
        switch (userTypeId) {
            case UserTypeEnum.Agency:
                return 'Agency Pages [Logout Alert]'
            case UserTypeEnum.Player:
                return 'Player Pages [Logout Alert]'
            case UserTypeEnum.MasterUser:
                return 'Club Pages [Logout Alert]'
            case UserTypeEnum.Staff:
                return 'Head Coach Pages [Logout Alert]'
            default:
                return 'unknown page'
        }
        
    }
}

export function checkActiveUserSessions() {
    return async (dispatch, getState) => {
        const request = {
            method: 'post',
            url: 'Users/CheckActiveUserSessions',
        };
        await createApi(request);
    };
}

export function logoutWithRedirectWithState(url, location) {
    return async (dispatch, getState) => {
        const pageName = dispatch(getUserPageName());
        await dispatch(userActivityInsert({
            Message: 'Viewed Log Out Alert Pop-up',
            PageName: pageName,
        }));
        const request = {
            type: AUTH_LOGOUT,
            method: 'post',
            url: '/Users/Logout',
        };
        await dispatch(createApiThunk(request));
        dispatch(createAction(AUTH_LOGOUT_GLOBAL_DISPOSE)());
        clearState();
        storedFilter.clearFilter();
        const isLogOut = {
            isLogOut: true,
            location: location,
        }
        localStorage.setItem('IS_LOGOUT', JSON.stringify(isLogOut));
        window.location.replace(url);
    }
}


export const setupAgencyFeature = createAction(AUTH_SET_UP_AGENCY_FEATURE);

export const deactivateAgencyFeature = createAction(AUTH_DEACTIVATE_AGENCY_FEATURE);

export const hideTermsAndConditions = createAction(AUTH_HIDE_TERMS_AND_CONDITIONS);
