import { translate } from '../services/localization';
import { dateDiffInDaysSigned, dateDiffInWeeks, dateDiffInYears, monthDiff } from 'services/utils';
import moment from 'moment/moment';

export const foramtDate = (unformatDate) => {
    const date = new Date(unformatDate);
    const month = parseInt(date.getMonth() + 1);
    const day = parseInt(date.getDate());

    return `${date.getFullYear()}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
    }`;
};

export const daysFromNow = (date) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    return Math.round(Math.abs((new Date().getTime() - date.getTime()) / oneDay));
};

export const monthsFromNow = (date) => {
    return new Date().getMonth() - date.getMonth();
};

const getMonthNames = () => {
    return [
        translate('calendar.months.january'),
        translate('calendar.months.february'),
        translate('calendar.months.march'),
        translate('calendar.months.april'),
        translate('calendar.months.may'),
        translate('calendar.months.june'),
        translate('calendar.months.july'),
        translate('calendar.months.august'),
        translate('calendar.months.september'),
        translate('calendar.months.october'),
        translate('calendar.months.november'),
        translate('calendar.months.december'),
    ];
};

const getShortMonthNames = () => {
    return [
        translate('calendar.monthsAbbreviated.jan'),
        translate('calendar.monthsAbbreviated.feb'),
        translate('calendar.monthsAbbreviated.mar'),
        translate('calendar.monthsAbbreviated.apr'),
        translate('calendar.monthsAbbreviated.may'),
        translate('calendar.monthsAbbreviated.jun'),
        translate('calendar.monthsAbbreviated.jul'),
        translate('calendar.monthsAbbreviated.aug'),
        translate('calendar.monthsAbbreviated.sep'),
        translate('calendar.monthsAbbreviated.oct'),
        translate('calendar.monthsAbbreviated.nov'),
        translate('calendar.monthsAbbreviated.dec'),
    ];
};

export const stringToDate = (myString) => {
    return new Date(myString);
};

export const getMonthName = (myDate) => {
    const monthNames = getMonthNames();
    return monthNames[stringToDate(myDate).getMonth()];
};

export const getShortMonthName = (myDate) => {
    const monthNames = getShortMonthNames();
    return monthNames[stringToDate(myDate).getMonth()];
};

const getWeekdays = () => {
    return [
        translate('calendar.weekDays.sunday'),
        translate('calendar.weekDays.monday'),
        translate('calendar.weekDays.tuesday'),
        translate('calendar.weekDays.wednesday'),
        translate('calendar.weekDays.thursday'),
        translate('calendar.weekDays.friday'),
        translate('calendar.weekDays.saturday'),
    ];
};

export const getShortWeekDay = (myDate) => {
    const weekday = getWeekdays();
    return weekday[stringToDate(myDate).getDay()].substr(0, 3);
};

export const getShortWeekDayRange = (start, end) => {
    if (start === end || end == null) {
        return getShortWeekDay(start);
    } else {
        return `${getShortWeekDay(start)}-${getShortWeekDay(end)}`;
    }
};

export const countDaysDifference = (date1, date2) => {
    // The number of milliseconds in one day
    var ONE_DAY = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = Math.abs(date1_ms - date2_ms);

    // Convert back to days and return
    return Math.round(difference_ms / ONE_DAY);
};

export const formatLastUpdatedAd = (lastUpdated, now = new Date()) => {
    const lastDigitIsOne = (n) => {
        return n.toString().slice(-1) === '1';
    };

    const getStringOf = (time, diffType) => {
        return `${translate('playerAds.formatAd.updated')} ${time} ${diffType}${
            lastDigitIsOne(time) ? '' : 's'
        } ${translate('playerAds.formatAd.ago')}`;
    };

    const updatedOn = new Date(lastUpdated);

    const daysPassed = dateDiffInDaysSigned(now, updatedOn);

    if (daysPassed < 0) {
        throw new Error(translate('playerAds.formatAd.lastUpdatedFuture'));
    }
    // 1 day - 6 days
    if (daysPassed < 7) {
        return !daysPassed
            ? translate('playerAds.formatAd.updatedToday')
            : getStringOf(daysPassed, translate('playerAds.formatAd.day'));
    }

    const weeksPassed = dateDiffInWeeks(now, updatedOn);
    // return 1 week - 4 weeks
    if (weeksPassed < 5) {
        return getStringOf(weeksPassed, translate('playerAds.formatAd.week'));
    }

    const monthsPassed = monthDiff(now, updatedOn);
    // return 1 month - 11 months
    if (monthsPassed < 12) {
        return getStringOf(monthsPassed, translate('timeUnits.month'));
    }

    // return 1 year - … years
    return getStringOf(dateDiffInYears(now, updatedOn), translate('playerAds.formatAd.year'));
};

export const isToday = (date) => {
    const today = new Date();
    return today.toDateString() === new Date(date).toDateString();
};

export const calculateAge = (dateOfBirth) => {
    const dobDate = new Date(dateOfBirth);

    if (isNaN(dobDate.getTime())) {
        return null;
    }

    const currentDate = new Date();

    let age = currentDate.getUTCFullYear() - dobDate.getUTCFullYear();

    if (
        currentDate.getUTCMonth() < dobDate.getUTCMonth() ||
        (currentDate.getUTCMonth() === dobDate.getUTCMonth() &&
            currentDate.getUTCDate() < dobDate.getUTCDate())
    ) {
        age--;
    }

    return age;
};

export const formatDateToDayMonthYear = (utcDate) => {
    const date = new Date(utcDate);

    if (isNaN(date.getTime())) {
        return null;
    }

    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so we add 1
    const year = date.getFullYear();

    // Ensure leading zeros for day and month if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
};

export const formatDateToFullMonthYear = (utcDate) => {
    const date = new Date(utcDate);
    return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
};

export const formatDateToShortMonthYear = (utcDate) => {
    if (!utcDate) {
        return '';
    }
    const date = new Date(utcDate);
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
};

export const renderDateFormat = (startDate, endDate) => {
    const date = moment(startDate);
    const month = date.format('MMMM');
    const day = date.format('ddd');

    const dayOfMonth = date.format('DD');
    let secondDayOfMonth = null;

    if (endDate != null) {
        secondDayOfMonth = moment(endDate);

        if (date.format('ddd') === secondDayOfMonth.format('ddd')) {
            secondDayOfMonth = null;
        }
    }

    return {
        date,
        secondDayOfMonth,
        dayOfMonth,
        month,
        day,
    };
};

export const convertToUtcDate = (date) => {
    if (!date) return null;

    const localDateWithoutTimeZone = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    return moment.utc(localDateWithoutTimeZone);
};

export const getDifferenceInYearsAndMonths = (startDate, endDate) => {
    if (!startDate) {
        return '';
    }
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();

    const diffTime = Math.abs(end - start);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    const diffMonth = Math.floor(diffDays / 30);

    if (diffMonth < 12) {
        return `${diffMonth} month${diffMonth > 1 ? 's' : ''}`;
    }

    const remainingMonths = diffMonth % 12;
    const years = Math.floor(diffMonth / 12);
    let result = `${years} year${years > 1 ? 's' : ''}`;

    if (remainingMonths) {
        result = `${result} ${remainingMonths} month${remainingMonths > 1 ? 's' : ''}`;
    }

    return result;
};

export const getIsDateInFuture = (date) => {
    if (!date) {
        return false;
    }
    const today = new Date();
    return new Date(date) > today;
};
