import userActivityInsert from '../app/user-activity/actions/user-activity.actions';
import config from 'config';
import { agencyPaths, userPaths, coachPaths, playerPathsV2 } from 'routes/paths';
import { UserTypeEnum } from 'constants/enums';
import { AuthInfoModel } from 'store/auth/auth-state';

const useAppcues = async (dispatch, auth: AuthInfoModel) => {
    const { userId } = auth;

    // now that we have a reference to the user, we can identify them with Appcues
    let userType = 'unknown';
    switch (auth.userTypeId) {
        case UserTypeEnum.MasterUser:
        case UserTypeEnum.AdditionalUser:
        case UserTypeEnum.FriendlyMatchSupervisor:
            userType = 'squad';
            break;
        case UserTypeEnum.Agency:
            userType = 'agency';
            break;
        case UserTypeEnum.Player:
            userType = 'player';
            break;
        case UserTypeEnum.Staff:
        case UserTypeEnum.PersonalClubAccount:
            userType = 'staff';
            break;
    }

    if (userType !== 'squad' && userType !== 'agency' && userType !== 'staff') {
        return;
    }

    let appcuesCurrentFlowName = '';
    const apiId = config.appcues.apiId;
    window.AppcuesSettings = { enableURLDetection: true };
    const scriptSource = `https://fast.appcues.com/${apiId}.js`;
    await loadAppcuesScript(scriptSource);

    window.Appcues.identify(userId, {
        //email: auth.userEmail,
        displayName: auth.userFullName,
        squadId: auth.squadId,
        agencyId: auth.agencyId,
        userType: userType,
        agencyName: auth.agencyName,
        squadName: auth.clubName,
        staffId: auth.staffId,
    });

    window.Appcues.on('step_interacted', function (event) {
        const pageName = parseCurrentPage();
        appcuesCurrentFlowName = event.flowName;
        dispatch(
            userActivityInsert({
                Message: `${event.interactionType} ${event.interaction.text} (step ${event.stepNumber})`,
                PageName: `${pageName} [${event.flowName}]`,
            })
        );
    });

    window.Appcues.on('nps_score', function (event) {
        const pageName = parseCurrentPage();
        appcuesCurrentFlowName = event.flowName;
        dispatch(
            userActivityInsert({
                Message: `Survey ${event.flowName}, score ${event.score}`,
                PageName: `${pageName} [${event.flowName}]`,
            })
        );
    });

    window.Appcues.on('nps_feedback', function (event) {
        const pageName = parseCurrentPage();
        appcuesCurrentFlowName = event.flowName;
        dispatch(
            userActivityInsert({
                Message: `Survey ${event.flowName}, feedback ${event.feedback}`,
                PageName: `${pageName} [${event.flowName}]`,
            })
        );
    });

    window.Appcues.on('all', function (eventName, event) {
        if (eventName === undefined || eventName === null) {
            const pageName = parseCurrentPage();
            dispatch(
                userActivityInsert({
                    Message: `${event.name}`,
                    PageName: `${pageName} [${appcuesCurrentFlowName}]`,
                })
            );
        }
    });
};

export const resetAppcues = async (dispatch) => {
    if (window.Appcues) {
        window.Appcues.reset();
    }
};

const parseCurrentPage = () => {
    const url = new URL(window.location.href);

    if (url === null || url === undefined) {
        return `Appcues`;
    }

    const path = url.pathname.split('?')[0];

    switch (path) {
        case userPaths.home:
            return 'Landing Page';
        case agencyPaths.landingPagePlayers:
            return 'Agency Players';
        case agencyPaths.landingPageActivity:
            return 'Agency Home';
        case agencyPaths.landingPageHeadCoaches:
            return 'Agency Head Coaches';
        case agencyPaths.pitchPage:
        case userPaths.clubAds:
            return 'Pitch';
        case userPaths.pitchInsights:
        case agencyPaths.pitchInsights:
            return 'Pitch Insights';
        case userPaths.messaging:
            return 'Messaging';
        case userPaths.plusPitch:
        case agencyPaths.plusPitch:
            return 'Plus Pitch';
        case agencyPaths.customPitch:
            return 'Custom Pitch';
        case userPaths.superPitch:
            return 'Super Pitch';
        case userPaths.friendlyMatches:
            return 'Friendlies';
        case userPaths.loanInsights:
            return 'VLA';
        case userPaths.playerSearch:
            return 'Search';
        case userPaths.events:
            return 'Event Page';
        case agencyPaths.publicProfile:
            return 'Public Profile';
        case userPaths.mySquad:
            return 'Squad';
        case playerPathsV2.myAgency:
        case playerPathsV2.latestActivity:
        case playerPathsV2.invitePlayers:
            return 'My Page';
        case coachPaths.myProfile:
            return 'Head Coach Profile';
    }

    if (path.indexOf('/playerads/') === 0) {
        return 'Player Ads';
    }

    if (path.indexOf('/create-player-ads') === 0) {
        return 'Player Ads';
    }

    if (path.indexOf('/profile/') === 0) {
        return 'Player Profile';
    }

    if (path.indexOf('/coach-profile/') === 0) {
        return 'Coach Profile';
    }

    if (path.indexOf('/editprofile/') === 0) {
        return 'Profile';
    }

    return `Appcues ${path}`;
};

function loadAppcuesScript(src) {
    // create a promise for the newScript
    return new Promise(
        function (resolve, reject) {
            // create an html script element
            var script = document.createElement('script');
            // set the source of the script element
            script.src = src;
            // set a listener when the script element finishes loading the script
            script.addEventListener(
                'load',
                function () {
                    // resolve if the script element loads
                    resolve();
                }.bind(this)
            );
            // set a listener when the script element faces any errors while loading
            script.addEventListener(
                'error',
                function (e) {
                    // reject if the script element has an error while loading the script
                    reject(e);
                }.bind(this)
            );
            // append the script element to the body
            document.body.appendChild(script);
        }.bind(this)
    );
}

export default useAppcues;
