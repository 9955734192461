import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import AgencyFinderService from 'api/player-v2/agency-finder.service';
import { GeneralAgencyInfoModel, AgentPlayerRepresentationRequestModel } from 'api/player-v2/models';
import { AgentPlayerAgreementTypeEnum, AgentPlayerInvitationStatusEnum } from 'api/agency/player/shared/shared-models';
import historyAccessor from 'history-accessor';
import { loadUserPreferenceInfo } from "store/userPreference/userPreference.service";
import userActivityInsert from "app/user-activity/actions/user-activity.actions";
import { playerPathsV2 } from "routes/paths";
import { getAuth } from 'store/auth/authReducer';
import { getPlayerUserPermissions } from 'store/userPreference/userPreference.reducer';

export enum AgencyProfileSteps {
  Profile = 1,
  Details = 2,
  Representation = 3
}

class State {
  isLoading: boolean;
  processing: boolean;
  isRemoveAgencyModalOpen: boolean;
  noSpecificExpiryDate: boolean;
  expireDate: string;
  agreementType: AgentPlayerAgreementTypeEnum;
  currentStep: AgencyProfileSteps;
  playerAgency: GeneralAgencyInfoModel | null;
  playerAgencyLoaded: boolean;
  isRepresentationRequestsLoaded: boolean;
  representationRequests: AgentPlayerRepresentationRequestModel[];
}

const defaultState: State = {
  isLoading: false,
  processing: false,
  isRemoveAgencyModalOpen: false,
  noSpecificExpiryDate: false,
  expireDate: null,
  agreementType: AgentPlayerAgreementTypeEnum.None,
  currentStep: AgencyProfileSteps.Profile,
  playerAgency: null,
  playerAgencyLoaded: false,
  isRepresentationRequestsLoaded: false,
  representationRequests: [],
}

const stateController = new StateController<State>(
  'PLAYERV2/MY-AGENCY-PAGE',
  defaultState
)

class Actions {
  public static dispose() {
    return (dispatch) => {
      dispatch(stateController.setState({ ...defaultState }));
    }
  }

  public static loadInitData(isTracked?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const playerId = getState().auth.playerId;

      if (isTracked) {
        dispatch(userActivityInsert({
          Message: 'Clicked Agency',
          PageName: 'My page [Agency]',
          PlayerId: playerId,
        }))
      }

      dispatch(loadUserPreferenceInfo());
      dispatch(Actions.getPlayerAgencyData());
      dispatch(Actions.getAgentPlayerRepresentationList());
    }
  }
  public static sendUserActivity(message: string, agencyId?: number, pageName?: string,) {
    return (dispatch, getState: () => AppState) => {
      const { playerId } = getAuth(getState());

      dispatch(userActivityInsert({
        Message: message,
        PageName: pageName ? pageName : 'My page [Agency]',
        PlayerId: playerId,
        AgencyId: agencyId ? agencyId : null,
      }))
    }
  }
  public static startAgentFinder() {
    return (dispatch, getState: () => AppState) => {
      dispatch(Actions.sendUserActivity('Clicked Start Agent Finder'))
      historyAccessor.push(playerPathsV2.agentFinder)
    }
  }

  public static getPlayerAgencyData() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isLoading: true }));
      try {
        const data = await AgencyFinderService.getAgencyInfo();
        dispatch(stateController.setState({
          playerAgency: data.generalPlayerInfos,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        const agencyId = getState().playerV2.myAgency.playerAgency ? getState().playerV2.myAgency.playerAgency?.agent?.id : null;
        dispatch(stateController.setState({
          isLoading: false,
          playerAgencyLoaded: true
        }));
        dispatch(Actions.sendUserActivity('Viewed Agency Page', agencyId));
      }
    }
  }

  public static getAgentPlayerRepresentationList() {
    return async (dispatch, getState: () => AppState) => {
      dispatch(stateController.setState({ isLoading: true }));
      try {
        const data = await AgencyFinderService.getAgentPlayerRepresentationList();
        dispatch(stateController.setState({
          representationRequests: data,
        }));
      } catch (err) {
        console.log(err);
      } finally {
        dispatch(stateController.setState({
          isLoading: false,
          isRepresentationRequestsLoaded: true
        }));
      }
    }
  }

  public static openRemoveAgencyModal() {
    return (dispatch, getState: () => AppState) => {
      const agencyId = getState().playerV2.myAgency.playerAgency.agent.id;
      dispatch(Actions.sendUserActivity('Remove Agency', agencyId, 'Agency [My Agent]'));
      dispatch(stateController.setState({ isRemoveAgencyModalOpen: true }));
    }
  }

  public static closeRemoveAgencyModal() {
    return (dispatch, getState: () => AppState) => {
      const agencyId = getState().playerV2.myAgency.playerAgency.agent.id;
      dispatch(Actions.sendUserActivity('Cancelled', agencyId, 'Agency [Removal]'));
      dispatch(stateController.setState({ isRemoveAgencyModalOpen: false }));
    }
  }

  public static confirmRemoveAgencyModal() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ processing: true }));
        const agencyId = getState().playerV2.myAgency.playerAgency.agent.id;
        dispatch(Actions.sendUserActivity('Confirmed', agencyId, 'Agency [Removal]'));

        // To add a test mode for credit swap, use dateTest
        // const dateTest = getState().agency.landingPage.homePagePlayers.dateTest;
        await AgencyFinderService.removeAgency();
        dispatch(stateController.setState({
          isRemoveAgencyModalOpen: false,
          currentStep: AgencyProfileSteps.Profile,
          playerAgency: null
        }));
        await dispatch(loadUserPreferenceInfo());
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ processing: false }));
      }
    }
  }

  public static goToStep(step: AgencyProfileSteps) {
    return (dispatch) => {
      dispatch(stateController.setState({ currentStep: step }));

      if (step === AgencyProfileSteps.Representation) {
        dispatch(Actions.sendUserActivity('Clicked View Representation Requests Status'))
      }
    }
  }

  public static backToProfile() {
    return (dispatch) => {
      dispatch(Actions.sendUserActivity('Clicked Back to Agency'));
      dispatch(stateController.setState({ currentStep: AgencyProfileSteps.Profile }));
    }
  }
  public static setAgreementType(type: AgentPlayerAgreementTypeEnum) {
    return (dispatch) => {
      dispatch(stateController.setState({ agreementType: type }))
    }
  }

  public static setExpireDate(date: string) {
    return (dispatch) => {
      if (date !== null) {
        dispatch(stateController.setState({ expireDate: date, noSpecificExpiryDate: false, }))
      } else {
        dispatch(stateController.setState({ expireDate: null, noSpecificExpiryDate: true }))
      }
    }
  }

  public static saveAgentDetails() {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ processing: true }));
        const substate = Selectors.getRoot(getState());
        await AgencyFinderService.changeAgentDetails({
          expiryDate: substate.expireDate,
          agreementType: substate.agreementType,
        });

        dispatch(stateController.setState({
          isLoading: false,
          playerAgency: {
            ...substate.playerAgency,
            expiryDate: substate.expireDate,
            agreementType: substate.agreementType,
            agreementPassed: true,
          }
        }));

        var source = new URL(window.location.href).searchParams.get("source");
        if (source === "contract-modal")
          historyAccessor.push("/");

        dispatch(Actions.goToStep(AgencyProfileSteps.Profile))
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(stateController.setState({ processing: false }));
      }
    }
  }

  public static initContractDetails() {
    return (dispatch, getState: () => AppState) => {
      const substate = Selectors.getRoot(getState());
      dispatch(stateController.setState(prevState => ({
        ...prevState,
        expireDate: substate.playerAgency.expiryDate,
        noSpecificExpiryDate: substate.playerAgency.agreementType && substate.playerAgency.expiryDate == null,
        agreementType: substate.playerAgency.agreementType
      })));
    }
  }
}

class Selectors {
  public static getRoot = (state: AppState): State => state.playerV2.myAgency;
  public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
  public static isPlayerAgencyLoaded = (state: AppState) => Selectors.getRoot(state).playerAgencyLoaded;
  public static isProcessing = (state: AppState) => Selectors.getRoot(state).processing;
  public static isRemoveAgencyModalOpen = (state: AppState) => Selectors.getRoot(state).isRemoveAgencyModalOpen;
  public static getPlayerAgency = (state: AppState) => Selectors.getRoot(state).playerAgency;
  public static getRepresentationRequests = (state: AppState) => Selectors.getRoot(state).representationRequests;
  public static getCurrentStep = (state: AppState) => Selectors.getRoot(state).currentStep;
  public static getNoSpecificExpiryDate = (state: AppState) => Selectors.getRoot(state).noSpecificExpiryDate;
  public static getExpireDate = (state: AppState) => Selectors.getRoot(state).expireDate;
  public static getAgreementType = (state: AppState) => Selectors.getRoot(state).agreementType;

  public static isRepresentationRequestsEmpty = (state: AppState) =>
    !Selectors.getRepresentationRequests(state) || Selectors.getRepresentationRequests(state).length === 0;

  public static getIsPlayerVerified = (state: AppState) =>
    Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.VerifiedAndConfirmed;

  public static getIsPlayerPreconnectedToAgent = (state: AppState) =>
    Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.PreconnectedToAgent;

  public static getVerifiedStatus = (state: AppState) => {
    if (Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.VerifiedAndConfirmed) {
      return 'Verified';
    }
    if (
      Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.SentToPlayer ||
      Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.SentToAgency
    ) {
      return 'Pending Verification';
    }
    if (Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.PreconnectedToAgent) {
      return 'Not Verified';
    }
    if (Selectors.getPlayerAgency(state)?.invitationStatusId === AgentPlayerInvitationStatusEnum.PreconnectedToNotTrusted) {
      return 'Pending Invitation';
    }

    return '';
  };

  public static getSectionVisibilityForMinorPlayer = (state: AppState): boolean => {
    const isMinorPlayer = !getPlayerUserPermissions(state)?.isPlayerReferralsAvailable && !getPlayerUserPermissions(state)?.isAgentFinderAvailable &&
      !getPlayerUserPermissions(state)?.isMessagesAvailable && !getPlayerUserPermissions(state)?.isPlayerSettingsAvailable;
    return !isMinorPlayer;
  }
}

const reducer = stateController.getReducer();

export {
  reducer as Reducer,
  State as State,
  Actions as Actions,
  Selectors as Selectors,
  stateController as Controller
};
