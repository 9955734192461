import createApiThunk from '../../../services/createApiThunk';
import * as types from 'store/actionTypes';
import { createAction } from 'redux-actions';
import {
    USER_EDITOR_MODAL,
    CHANGE_PASSWORD_MODAL,
    DELTE_PROFILE_WARNING
} from '../../../constants/modals';
import { MODAL_CLOSE, MODAL_OPEN } from 'store/actionTypes'
import { setLocaleToState } from 'services/localization'
import { USER_IS_CREATED, USER_IS_CREATING } from "app/edit-profile/reducer/edit-profile.reducer.js"
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { deactivateAgencyFeature } from "store/auth/auth.actions";
import { AgencyLandingService } from 'api/agency/agency-landing.service'
import { modalClose } from 'store/modals/modalsActions';
import { DEACTIVATE_AGENTS_FEATURE } from 'constants/modals';
import { PageType } from 'constants/enums';
import { Profile } from 'api/user-profile/models/profile';

export const getProfile = (userId, squadId) => {
    const request = {
        type: types.PROFILE_PERSONAL,
        method: 'get',
        scope: 'editProfile',
        url: `Users/GetUserProfile/${userId}/${squadId}`,
        success: types.PROFILE_PERSONAL.SUCCESS,
    };

    return createApiThunk(request);
};

export const updateProfile = (profile: Profile, callback: () => void = null, trackingObj = null) => async (dispatch, getState) => {
    
    const url = profile.requestId > 0 ? '/Users/UpdateUserRequest' : '/Users/UpdateUserProfile';
    
    const request = {
        type: types.UPDATE_PROFILE,
        method: 'post',
        scope: 'editProfile',
        url: url,
        payload: {
            id: profile.id,
            requestId: profile.requestId,
            firstName: profile.firstName,
            lastName: profile.lastName,
            fullName: `${profile.firstName} ${profile.lastName}`,
            userPositionId: 16, //profile.userPositionId,
            userPositionName: profile.userPositionName,
            emailPreferences: profile.emailPreferences,
            languageId: profile.languageId,
            userTypeId: profile.userTypeId || 0,
            staffProductAccess: profile.staffProductAccess
        },
        success: types.UPDATE_PROFILE.SUCCESS,
    };
    await dispatch(createApiThunk(request, trackingObj));
    await (dispatch(setLocaleToState(profile.languageId)))
    
    if (callback) callback()

};

export const updateUserPassword = profile => {
    const request = {
        type: types.UPDATE_PASSWORD,
        method: 'post',
        scope: 'editProfile',
        url: '/Users/ChangePassword',
        payload: {
            id: profile.id,
            oldPassword: profile.password,
            newPassword: profile.newPassword,
        },
        success: types.UPDATE_PASSWORD.SUCCESS,
    };

    return createApiThunk(request);
};

export const validateOldPassword = (profileId, password) => {
    const request = {
        type: types.VALIDATE_PASSWORD,
        method: 'post',
        scope: 'editProfile',
        url: `Users/ValidatePassword/${profileId}`,
        payload: {
            password,
        },
        success: types.VALIDATE_PASSWORD.SUCCESS,
    };

    return createApiThunk(request);
};

export const updateUserPasswordV2 = profile => {
    const request = {
        type: types.UPDATE_PASSWORD,
        method: 'post',
        scope: 'editProfile',
        url: 'v2/UserPassword/Update',
        payload: {
            password: profile.password,
            newPasword: profile.newPassword,
        },
        success: types.UPDATE_PASSWORD.SUCCESS,
    };

    return createApiThunk(request);
};

export const getSquadUsersProfiles = squadId => {
    const request = {
        type: types.GET_THE_PROFILE_USERS,
        method: 'get',
        scope: 'editProfile',
        url: `/Users/GetSquadUsersList/${squadId}`,
        success: types.GET_THE_PROFILE_USERS.SUCCESS,
    };

    return createApiThunk(request)
};

export const updateTheProfileUsers = (user, jobTitle, status) => {
    const request = {
        type: types.UPDATE_THE_PROFILE_USERS,
        method: 'put',
        scope: 'editProfile',
        url: '/Users/ChangePassword',
        payload: {
            user,
            jobTitle,
            status,
        },
        success: types.UPDATE_THE_PROFILE_USERS.SUCCESS,
    };
    return createApiThunk(request);
};

export const deleteProfile = userId => {
    const request = {
        type: types.DELETE_THE_PROFILE_USERS,
        method: 'delete',
        scope: 'editProfile',
        url: `/Users/${userId}`,
        success: types.DELETE_THE_PROFILE_USERS.SUCCESS,
    };

    return createApiThunk(request);
};

export const deleteRequest = requestId => {
    const request = {
        type: types.DELETE_THE_PROFILE_USERS,
        method: 'delete',
        scope: 'editProfile',
        url: `/Users/UserRequest/${requestId}`,
        success: types.DELETE_THE_PROFILE_USERS.SUCCESS,
    };

    return createApiThunk(request);
};

export const openDeleteProfileModal = (user) => {
    return (dispatch) => {
        let command = {
            type: MODAL_OPEN,
            payload: { 
                id: DELTE_PROFILE_WARNING, 
                content: {
                    onDelete: () => {
                        dispatch(userActivityInsert({
                            PageName: `Edit Profile [Users]`,
                            Message: `Remove User: ${user.firstName} ${user.lastName}`,
                            PageType: PageType.EditProfile
                        }))

                        if(user.requestId > 0){
                            dispatch(deleteRequest(user.requestId))
                        }
                        else{
                            dispatch(deleteProfile(user.id))
                        }                        
                        dispatch(closeDeleteProfileModal())
                    },
                    onModalClose: () => dispatch(closeDeleteProfileModal())
                }
            }
        }
        dispatch(command);
    }
    
}

export const closeDeleteProfileModal = () => {
    return (dispatch) => {
        let command = {
            type: MODAL_CLOSE,
            payload: DELTE_PROFILE_WARNING
        }
        dispatch(command);
    }
}

export const addUserProfile = (profile: Profile, userId: number, callback: () => void = null) => async (dispatch, getState) => {
    const request = {
        type: types.ADD_THE_PROFILE_USERS,
        method: 'post',
        scope: 'editProfile',
        url: '/Users/AddUserProfile',
        payload: {
            id: 0,
            firstName: profile.firstName,
            lastName: profile.lastName,
            clubId: profile.squadId,
            email: profile.email,
            userPositionId: 16, //profile.userPositionId,
            phone: profile.phone,
            phoneCodeAreaId: profile.phoneCodeAreaId,
            userPositionName: profile.userPositionName,
            userTypeId: profile.userTypeId || 0,
            requestedByUserId: userId,
            registrationSource: 'Add new user',
            status: 1,
            staffProductAccess: profile.staffProductAccess
        },
        success: types.ADD_THE_PROFILE_USERS.SUCCESS,
    };
    dispatch({ type: USER_IS_CREATING });
    let res = await dispatch(createApiThunk(request));
    dispatch({ type: USER_IS_CREATED });
    if (callback) callback()
    return res;
};

export const modalCloseChangePassword = createAction(
    types.MODAL_CLOSE,
    () => CHANGE_PASSWORD_MODAL,
);

export const modalCloseUserEditor = createAction(
    types.MODAL_CLOSE,
    () => USER_EDITOR_MODAL,
);

export const deactivateAgencyV2 = () => {
    return async (dispatch, getState) => {
        try {
            dispatch({ type: types.AGENT_FEATURE_DEACTIVATION_PROCESSING_STATE, payload: true})
            await AgencyLandingService.deactivateAgencyFreeTrial()
            dispatch(deactivateAgencyFeature())
            dispatch({ type: types.AGENT_FEATURE_DEACTIVATION_DONE })
            dispatch(modalClose(DEACTIVATE_AGENTS_FEATURE))
            dispatch(userActivityInsert({
                PageName: 'Edit Profile [Agents]',
                Message: 'Deactivated Agents Feature',
                PageType: PageType.EditProfile
            }))
        } catch (e) {
            console.error(e)
        } finally {
            dispatch({ type: types.AGENT_FEATURE_DEACTIVATION_PROCESSING_STATE, payload: false})
        }
    }
}

export const clearRequestSession = createAction(types.CLEAR_PASSWORD_REQUEST);
export const validatePassword = createAction(types.VALIDATE_EDIT_PASSWORD);