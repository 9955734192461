// Paths wich are accessable for authorized and non authorized users.
// Mast consider that you can`t use any of lightweight path names in other routes
export const lightweightPaths = {
    eventStream: '/event-stream',
    eventStream2: '/event-stream/2',
    eventStream3: '/event-stream/3',
    unsubscribed: '/unsubscribed',
    gbeHubspotFrame: '/gbe-hubspot-frame',
    uiKit: '/ui-kit',
    routeKit: '/route-kit',
    xtvWebinar: '/xtv-webinar',
    //playerInfo: '/p/:playerHash/:code/:clubHash/:receiver?',

    errorPage: '/404',
};

export const lightweightPaths2 = {
    playerInfo: '/p/:playerHash/:code/:clubHash/:receiver?',
};

export const redirects = {
    players: '/players',
};

export const anonymousPaths = {
    landing: '/',
    login: '/login',
    welcomeBackLogin: '/welcome/login',
    requestAgencyInvitation: '/request-agency-invitation',
    loginV2: '/login-v2', // -
    demoRequest: '/request-a-demo', // -
    passwordRecovery: '/password-recovery', // -
    passwordRecoveryV2: '/forgot-password',
    agencyExpired: '/agency-expired-link',
    resetPassword: '/reset-password',
    updatePassword: '/update-password',
    confirmAccount: '/confirmaccount/:token',
    video: '/video', // -
    videoV2: '/tv',
    expiredAds: '/expired-ads',
    signIn: '/sign-in',
    virtualSummitLogin: '/virtual-summit/login',
    virtualSummitRegister: '/virtual-summit/register',
    virtualSummitLandingRegister: '/virtual-summit/register-landing',
    //summit v2 routes
    eventLogin: '/event/login',
    eventRegister: '/event/register',
    eventLandingRegister: '/event/register-landing',
    //end
    adminExternalPersonalize: '/admin-external/:token',
    agencyOnboarding: '/agency/agent/onboarding/:token',
    agencyPlayerOnboarding: '/agency/player/confirm/:token',
    agencyPlayerOnboardingNew: '/agency/player/confirm-new/:token',
    // Redirects
    registration: '/registration',
    registrationAgencyMode: '/registration/:agencyEmail/:agencyName',
    events: '/our-events',
    dealDayEvent: '/Deal-day',
    incomingEventTracked: '/incoming-event',

    // new flow
    agencyOnboardingViaLink: '/agency/agent/registration',
    accountBlocked: '/account-blocked',
    customPitchPublished: '/published-pitch/:id',
    playerOnboardingByEmail: '/agency/player/onboard-by-email/:token',
    agencyCoachOnboarding: '/agency/coach/confirm/:token',
    personalEmailConfirm: '/personal-email-confirm/:token',
    claimYourProfile: '/claim-your-profile/:token',
};

export const commonPagesPaths = {
    newDataProcessingTerms: '/common/new-data-processing-terms',
    newTermsOfService: '/common/new-terms-of-service',
    agencyTermsOfService: '/common/agency-terms-of-service',
    playerTermsOfService: '/common/player-terms-of-service',
    coachTermsOfService: '/common/coach-terms-of-service',
    playerHealthConsent: '/common/player-health-consent',
    playerCodeOfConduct: '/common/player-code-of-conduct',
    playerAcknowledgement: '/common/player-acknowledgement',
    userManual: '/common/user-manual',
    privacyPolicyB2C: '/common/privacy-notice-B2C',
    coachPrivacyPolicy: '/common/privacy-policy-coach',
    staffTermsOfService: '/common/staff-terms-of-service',
    staffCodeOfConduct: '/common/staff-code-of-conduct',
    // updateEmail: '/update-email',

    jobs: '/jobs',
    // virtualSummitUnsubscribe: '/virtual-summit/unsubscribe',
    eventVideo: '/event-video',
    //summit v2 routes
    eventUnsubscribe: '/event/unsubscribe',
    //end

    // Old routes for redirect
    termsOfUse: '/common/terms-of-use',
    privacyPolicy: '/common/privacy-policy',
    privacyPolicyPrevious: '/common/privacy-policy-previous',
    cookiePolicy: '/common/cookie-policy',
    antiSlaveryPolicy: '/common/anti-slavery-policy',
    apiTermsOfService: '/common/api-terms-of-service',
};

export const playerPaths = {
    homePage: '/home',
    myProfile: '/profile',
    latestActivity: '/latest-activity',
    emailConfirmationFlow: '/email-confirmation',
    emailConfirmPage: '/confirm-user-email',
    myAgentFlow: '/my-agent',
    addAgent: '/add-agent',
    myMessages: '/my-messages',
};

export const playerPathsV2 = {
    homePage: '/home',
    myAgency: '/my-agency',
    agentFinder: '/agent-finder',
    messaging: '/messaging',
    latestActivity: '/latest-activity',
    settings: '/settings',
    myProfileOld: '/profile-old',
    myProfile: '/profile',
    inviteAgency: '/invite-agency',
    agencyConfirm: '/agency-confirm',
    emailVerification: '/email-verification',
    emailConfirmPage: '/confirm-user-email',
    dealConfirmPage: '/deal-confirmation',
    invitePlayers: '/invite-players',
    agencyProfile: '/agency-profile/:agencyName',
    playerPoints: '/player-points',
    updateEmail: '/update-email',
    playerPreferences: '/player-preferences',
};

export const agencyPaths = {
    //Player page
    // myPageAgent: '/mypage/agent',
    // myPageAccount: '/mypage/account',
    // confirmPage: '/confirm',
    messaging: '/messaging',
    playerVerification: '/player-verification',
    inviteAgents: '/invite-agents',
    addPlayers: '/add-players',
    addCoaches: '/add-coaches',
    coachVerification: '/coach-verification',
    pitchPage: '/pitch',
    events: '/events',
    eventRegistration: '/event/registration',
    pitchInsights: '/pitch-insights',
    plusPith: '/plus-pitch',
    customPitch: '/custom-pitch',
    landingPage: '/landing-page',
    playerPoints: '/player-points/:id?',
    playerSearch: '/playersearch',
    playerSearchOld: '/playersearch-old',
    playerSearchShotlist: '/playersearch-old/shortlist',
    landingPageActivity: '/landing-page/activity',
    landingPagePlayers: '/landing-page/players',
    landingPageHeadCoaches: '/landing-page/head-coaches',
    landingPageAgencyUsers: '/landing-page/agency-users',
    landingPageConnection: '/landing-page/connections',
    editProfile: '/editprofile',
    editProfileProfile: '/editprofile/profile',
    editProfileUsers: '/editprofile/users',
    editProfileNotifications: '/editprofile/notifications',
    editProfileDocuments: '/editprofile/documents',
    agencyProfilePreviw: '/agency-profile/preview',
    agencyProfile: '/agency-profile/:agencyName',
    coachProfile: '/coach-profile/:id',
    subscriptionFlow: '/subscription',
    playerProfileOld: '/profile-old/:id',
    playerProfile: '/profile/:id',
    setNewpassword: '/set-password',
    publicProfile: '/public-profile',
    swapPlayer: '/swap-player/:id?',
    updateEmail: '/update-email',
    toSwapPlayer: (id) => (id ? `/swap-player/${id}` : `/swap-player`),
    reonboardingFlow: '/reonboarding',
};

export const userPaths = {
    track: '/track',
    home: '/home',
    mySquad: '/mysquad',
    //mySquadv2: '/mysquadv2',
    messaging: '/messaging',
    playerSearch: '/playersearch',
    playerSearchOld: '/playersearch-old',
    shortList: '/playersearch/shortlist',
    similarity: '/playersearch/similarity',
    superPitchCreate: '/superpitch',
    plusPitchCreate: '/pluspitch-create',
    createPlayerAds: '/create-player-ads',

    reonboardingFlow: '/reonboarding',
    recruitPlayers: '/recruit-players',

    // Player Ads page
    playerAds: '/playerads',
    playerAds_Activity: '/playerads/activity',
    playerAds_SavedPitches: '/playerads/saved-pitches',
    playerAds_MyAds: '/playerads/my-ads',

    // Player Ads old
    playerAdsOld: '/playerads-old',
    playerAdsOld_Activity: '/playerads-old/activity',
    playerAdsOld_SavedPitches: '/playerads-old/saved-pitches',
    playerAdsOld_MyAds: '/playerads-old/my-ads',

    // Pitch Page
    clubAds: '/clubads',
    pitchInsights: '/clubads/pitch-insights',
    plusPitch: '/clubads/pluspitch',
    superPitch: '/clubads/superpitch',
    oldPusPitch: '/pluspitch',

    // Agency routes
    agents: '/agents',
    agentsMyNetwork: '/agents/my-network',
    agencyProfile: '/agency-profile/:agencyName',
    coachProfile: '/coach-profile/:id',
    friendlyMatches: '/friendlymatches',
    financialInsight: '/financial-insights',
    loanInsights: '/virtual-loan-assistant',
    playerPoints: '/player-points/:id?',
    playerPointsSampleMode: '/player-points/sample-mode',
    profileOld: '/profile-old/:id/:old?',
    profile: '/profile/:id/:old?',
    docsButtons: '/docs/buttons',
    editProfileProfile: '/editprofile/profile',
    editProfileUsers: '/editprofile/users',
    editProfileDocuments: '/editprofile/documents',
    editProfileEmailPreferences: '/editprofile/notifications',
    editProfileAgents: '/editprofile/agents',
    events: '/events',
    eventsAgendaBuider: '/events/agenda-builder',
    logout: '/logout',
    //probably outdated
    //summitConfirm: '/virtual-summit/confirm',
    // summitAddExtraAttendees: '/virtual-summit/add-extra-attendees',
    // virtualSummitCompleteDetails: '/virtual-summit/complete-details',
    // virtualSummitMeetingAgenda: '/virtual-summit/meeting-agenda',
    //summit v2 routes
    //probably outdated
    //eventConfirm: '/event/confirm',
    eventRegistration: '/event/registration',
    eventCompleteDetails: '/event/complete-details',
    eventMeetingAgenda: '/event/meeting-agenda',
    //end
    landingFriendlies: '/landing/friendly-proposals',
    landingOutdatedAds: '/landing/outdated-ads',
    landingPlayerProposals: '/landing/player-proposals',
    landingClubAdsProposals: '/landing/club-ads-proposals',
    agencyClubOnboarding: '/agency/club/register',
    meetings: '/meetings',
    meetingRoom: '/meeting-room/:id',
    coachFinder: '/coachfinder',
    updateEmail: '/update-email',
    staffProfile: '/staff-profile/:id',
    claimYourProfile: '/claim-your-profile',
    personalEmailConfirm: '/personal-email-confirm/:token',
    staffAccessGranted: '/staff-access-granted',
};

export const coachPaths = {
    homePage: '/home',
    myProfile: '/profile',
    settings: '/settings',
    emailVerification: '/email-verification',
    emailConfirmPage: '/confirm-user-email',
    agencyProfile: '/agency-profile/:agencyName',
    agencyConfirm: '/agency-confirm',
};

export const personalAccountPaths = {
    homePage: '/home',
    settings: '/settings',
    messages: '/messages',
    staffProfile: '/staff-profile/:id',
    personalEmailVerification: '/personal-email-verification',
    search: '/search',
};

export const staffProductPaths = {
    homePage: '/home',
    search: '/search',
    settings: '/settings',
    staffProfile: '/staff-profile/:id',
    personalEmailVerification: '/personal-email-verification',
};

export const adminPaths = {
    landing: '/admin',
    clubAds: '/admin/clubads',
    friendlyProposals: '/admin/friendly-proposals',
    friendlyMatches: '/admin/friendly-matches',
    upgradeBannerReport: '/admin/upgrade-banner',
    players: '/admin/players',
    expertCards: '/admin/expert-cards',
    squadsOld: '/admin/squads-old',
    squads: '/admin/squads',
    playerProposals: '/admin/player-proposals',
    soldPlayers: '/admin/sold-players',
    // soldPlayers: '/admin/sold-players/:reportDateStamp?/:playerId?',
    // soldPlayersOld: '/admin/sold-players-old/:reportDateStamp?/:playerId?',
    contacts: '/admin/contacts',
    userActivity: '/admin/usersactivity',
    adminPermissions: '/admin/adminpermissions',
    usersRequests: '/admin/usersrequests',
    agentsRequests: '/admin/agentsrequests',
    agencyEventRequests: '/admin/agencyeventrequests',
    clubPermissions: '/admin/clubpermissions',
    agencyPermissions: '/admin/agencypermissions',
    userPermissions: '/admin/userpermissions',
    messageManagement: '/admin/message-management',
    allUsers: '/admin/allusers',
    agencyOnboarding: '/admin/agency-onboard',
    events: '/admin/events',
    allPitches: '/admin/allpitches',
    declareInterestReport: '/admin/declareinterest',
    mapping: '/admin/mapping',
    newSquadsDowndload: '/admin/new-squads-download',
    successStory: '/admin/success-story',
    friendlySuccessStory: '/admin/friendly-success-story',
    eventAttendance: '/admin/event-attendance',
    eventPractical: '/admin/event-practical',
    playerVerification: '/admin/player-verification',
    agencies: '/admin/agencies',
    agencyPayments: '/admin/agency-payments',
    agencyCredits: '/admin/agency-credits',
    clubReonboarding: '/admin/club-reonboarding',
};

export const hubspotPaths = {
    home: 'https://www.transferroom.com',
    termsOfUse: 'https://www.transferroom.com/terms-of-use',
    privacyPolicy: 'https://www.transferroom.com/privacy-policy',
    cookiePolicy: 'https://www.transferroom.com/cookie-policy',
    antiSlaveryPolicy: 'https://www.transferroom.com/anti-slavery-policy',
};

const oldCommonPages = {
    cookiePolicyRedirects: [
        '/Common/CookiePolicy.aspx/',
        '/Common/CookiePolicy.aspx',
        '/CookiePolicy.aspx',
        '/CookiePolicy.aspx/',
    ], // Should be redirected to Hubspot page 'https://www.transferroom.com/cookie-policy'

    dataProcessingTermsRedirects: [
        '/Common/NewDataProcessingTerms.aspx/',
        '/Common/NewDataProcessingTerms.aspx',
        '/NewDataProcessingTerms.aspx',
        '/NewDataProcessingTerms.aspx/',
        '/Common/DataProcessingTerms.aspx/',
        '/Common/DataProcessingTerms.aspx',
        '/DataProcessingTerms.aspx',
        '/DataProcessingTerms.aspx/',
    ], // Should be redirected to /common/new-data-processing-terms'

    newTermsOfServiceRedirects: [
        '/Common/NewTermsOfService.aspx/',
        '/Common/NewTermsOfService.aspx',
        '/Common/NewTermsOfService.aspx?term',
        '/Common/NewTermsOfService.aspx?term/',
        '/TermsOfService.aspx',
        '/TermsOfService.aspx/',
    ], // Should be redirected to '/common/new-terms-of-service'

    privacyPolicyRedirects: [
        '/Common/PrivacyPolicy.aspx/',
        '/Common/PrivacyPolicy.aspx',
        '/PrivacyPolicy.aspx',
        '/PrivacyPolicy.aspx/',
    ], // Should be redirected to Hubspot page https://www.transferroom.com/privacy-policy

    termsOfUseRedirects: [
        '/Common/TermsOfUse.aspx/',
        '/Common/TermsOfUse.aspx',
        '/TermsOfUse.aspx',
        '/TermsOfUse.aspx/',
    ], // Should be redirected to Hubspot terms of use page 'https://www.transferroom.com/terms-of-use'

    loginsRedirects: ['/Login.aspx/', '/Login.aspx'], // Should be redirected to '/login'
};

const oldCommonPagesLowerCase = {};
for (var key in oldCommonPages) {
    oldCommonPagesLowerCase[key] = oldCommonPages[key].map((item) => item.toLowerCase());
}

export const getAlternativeASP_NET_URL_OR_NULL = (path) => {
    let lowerCasePath = path.toLowerCase();

    if (oldCommonPagesLowerCase.cookiePolicyRedirects.indexOf(lowerCasePath) !== -1) {
        window.location.replace(hubspotPaths.cookiePolicy);
        return null;
    } else if (oldCommonPagesLowerCase.dataProcessingTermsRedirects.includes(lowerCasePath)) {
        return commonPagesPaths.newDataProcessingTerms;
    } else if (oldCommonPagesLowerCase.newTermsOfServiceRedirects.includes(lowerCasePath)) {
        return commonPagesPaths.newTermsOfService;
    } else if (oldCommonPagesLowerCase.privacyPolicyRedirects.includes(lowerCasePath)) {
        return commonPagesPaths.privacyPolicy;
    } else if (oldCommonPagesLowerCase.termsOfUseRedirects.includes(lowerCasePath)) {
        window.location.replace(hubspotPaths.termsOfUse);
        return null;
    } else if (oldCommonPagesLowerCase.loginsRedirects.includes(lowerCasePath)) {
        return anonymousPaths.login;
    } else {
        return null;
    }
};

export const isLightweightPath = () => {
    const paths = Object.values(lightweightPaths);
    if (paths.some((path) => window.location.pathname.startsWith(getPathWithoutParams(path))))
        return true;
    return window.location.pathname.startsWith(`/p/`);
};

export const getPathWithoutParams = (path) => {
    if (!path) {
        throw Error('"path" should be defined!');
    }
    return (typeof path === 'function' ? path() : path).replace(/\/:[a-zA-Z?]+/g, '');
};

// export const getPathWithoutTokens = path => {
//     if (!path) {
//         throw Error('"path" should be defined!');
//     }
//     let result = `/${path.split('/')[1]}`;
//     return result;
// };

export const isAnonymousPath = (path) => {
    let findPath = Object.keys(userPaths).find((key) => userPaths[key] === path);
    if (path.indexOf(userPaths.virtualSummitCompleteDetails) !== -1) return false;
    if (path.indexOf(userPaths.eventCompleteDetails) !== -1) return false;
    if (findPath) return false;

    if (path.indexOf(anonymousPaths.agencyOnboarding.replace(':token', '')) !== -1) return true;
    if (path.includes(anonymousPaths.login)) return true;

    const pathWithoutParams = getPathWithoutParams(path);
    return !!Object.values(anonymousPaths).find(
        (x) => getPathWithoutParams(x) === pathWithoutParams
    );
};

export const hasPath = (paths, pathname) => {
    const urlPathnameWithoutParams = pathname.replace(/\/(\d+)/, '');
    return !!Object.values(paths).find((x) => getPathWithoutParams(x) === urlPathnameWithoutParams);
};

export const isAuthorizedPath = (path) => {
    let pathWithoutParams = getPathWithoutParams(path);
    //exceptional rules
    if (path.startsWith('/profile')) {
        pathWithoutParams = `/${path.split('/')[1]}`;
    }

    if (path.startsWith('/coach-profile')) {
        pathWithoutParams = `/${path.split('/')[1]}`;
    }

    if (path.startsWith(userPaths.eventMeetingAgenda)) {
        pathWithoutParams = `/${path.split('/')[1]}/${path.split('/')[2]}`;
    }
    if (
        path.includes(anonymousPaths.virtualSummitLogin) ||
        path.includes(anonymousPaths.eventLogin) ||
        path.includes(anonymousPaths.virtualSummitRegister) ||
        path.includes(anonymousPaths.eventRegister)
    )
        return false;

    let isUserPath = !!Object.values(userPaths).find(
        (path) => getPathWithoutParams(path) === pathWithoutParams
    );
    let isAgencyPath = !!Object.values(agencyPaths).find((p) => path.includes(p));
    let isPlayerPath = !!Object.values(playerPaths).find((p) => path.includes(p));
    return isUserPath || isAgencyPath || isPlayerPath;
};

export const getReturnUrlOrEmpty = (path) => {
    const determinator = '?returnUrl=';
    let returnUrl = null;
    let search = path;
    if (search != null && search.includes(determinator)) {
        returnUrl = search.replace(determinator, '');
    }
    return returnUrl;
};
